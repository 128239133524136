import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEO } from "src/components/seo"
import { Mobile } from "src/components/device"
import { Carousel } from "src/components/carousel"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"
import { VideoPlayer } from "src/components/video-player"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  Cell,
  Measure,
  HorizontalRuler,
  Headline,
  Paragraph,
  ParagraphTall,
  HeadlineHero,
  CellVerticalOffset,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      towards_title: string
      towards_text: string
      awareness: string
      services: string
      moments_title: string
      moments_copy: string
      expertise: string
    }
    images: {
      hero_video: any
      hearts: any
      ecosystem_diagram: any
      grid_2_data: any
      grid_2_trends: any
      grid_2_model: any
      grid_2_ecosystem: any
      vid_06: any
      vid_09: any
      vid_11: any
      grid_3_slide_01: any
      grid_3_slide_02: any
      grid_3_slide_03: any
      grid_3_slide_04: any
      grid_3_slide_05: any
      device_collage: any
      media20: any
    }
    videos: {
      hero_video: any
      hearts: any
      vid_06: any
      vid_09: any
      vid_11: any
      media20: any
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            video={videos.hero_video}
            image={images.hero_video}
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.intro}</Paragraph>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive video={videos.hearts} image={images.hearts} />
        </Block>
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.towards_title}</Headline>
          <Paragraph>{copy.towards_text}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <ImgFluid image={images.ecosystem_diagram} />
        </Block>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Grid gap>
              <Col>
                <ImgFluid image={images.grid_2_data} />
              </Col>
              <Col>
                <ImgFluid image={images.grid_2_model} />
              </Col>
            </Grid>
            <Grid gap>
              <Col>
                <ImgFluid image={images.grid_2_trends} />
              </Col>
              <Col>
                <ImgFluid image={images.grid_2_ecosystem} />
              </Col>
            </Grid>
          </Grid>
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <ParagraphTall>{copy.awareness}</ParagraphTall>
          </Col>
        </Grid>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive video={videos.vid_06} image={images.vid_06} />
        </Block>
      </Container>

      <Container>
        <Block>
          <Grid columns={{ bp0: "$1", bp3: "$2" }}>
            <Cell
              css={{
                bp3: {
                  paddingRight: "14px",
                },
              }}
              align={{ bp3: "$end" }}
            >
              <Measure measure={{ bp1: "$4/7", bp3: "$3/4" }}>
                <Mobile screen="#FFF">
                  <VideoLoopResponsive
                    ratio={1.7786666667}
                    video={videos.vid_09}
                    image={images.vid_09}
                  />
                </Mobile>
              </Measure>
            </Cell>
            <Cell
              css={{
                bp3: {
                  paddingLeft: "14px",
                },
              }}
              align={{ bp3: "$start" }}
            >
              <CellVerticalOffset />
              <Measure measure={{ bp1: "$4/7", bp3: "$3/4" }}>
                <Mobile screen="#FFF">
                  <VideoLoopResponsive
                    ratio={1.7786666667}
                    video={videos.vid_11}
                    image={images.vid_11}
                  />
                </Mobile>
              </Measure>
            </Cell>
          </Grid>
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.services}</Paragraph>
      </Container>

      <Container>
        <Block>
          <Carousel
            images={[
              images.grid_3_slide_01,
              images.grid_3_slide_02,
              images.grid_3_slide_03,
              images.grid_3_slide_04,
              images.grid_3_slide_05,
            ]}
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <Headline>{copy.moments_title}</Headline>
      </Container>

      <Container bleed>
        <Block>
          <ImgFluid image={images.device_collage} />
        </Block>
      </Container>

      <Container>
        <ParagraphTall>{copy.moments_copy}</ParagraphTall>
      </Container>

      <Container bleed>
        <Block>
          <VideoPlayer
            video={videos.media20}
            poster={images.media20}
            ratio="$16/9"
          />
        </Block>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
    </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "spotify" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        awareness
        towards_title
        towards_text
        services
        moments_title
        moments_copy
        expertise
      }
      images {
        hearts {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        hero_video {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        ecosystem_diagram {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_2_data {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_trends {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_model {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_ecosystem {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        vid_06 {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        vid_09 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        vid_11 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_slide_01 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_slide_02 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_slide_03 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_slide_04 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_slide_05 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        device_collage {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        media20 {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
      }
      videos {
        hero_video {
          src {
            src_large
            src_medium
            src_small
          }
        }
        hearts {
          src {
            src_large
            src_medium
            src_small
          }
        }
        vid_06 {
          src {
            src_large
            src_medium
            src_small
          }
        }
        vid_09 {
          src
        }
        vid_11 {
          src
        }
        media20 {
          src {
            src_large
            src_medium
            src_small
          }
        }
      }
    }
  }
`
