/*eslint-disable*/
import React, { useState, useMemo, useRef, useEffect, useCallback } from "react"
import { useMedia } from "use-media"
import { useInView } from "react-intersection-observer"

import { ImgFluid } from "../img-fluid"
import { Ratio } from "src/components/primitives"

import {
  ControlsBar,
  ButtonPlayPauseInv,
  ButtonPlayPause,
  ButtonMuteUnmute,
} from "./ui"

export const VideoPlayer = ({ video, poster, ratio }) => {
  const [started, setStarted] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [muted, setMuted] = useState(false)

  const isSmall = useMedia("(max-width: 640px)")
  const isMedium = useMedia("(max-width: 1023px)")
  const isLarge = useMedia("(min-width: 1024px)")

  const videoRef = useRef(null)

  const [inViewRef, inView] = useInView({
    threshold: 0.25,
  })

  const togglePlay = () => {
    if (playing) {
      pauseVideo()
    } else {
      playVideo()
    }
  }

  const playVideo = useCallback(() => {
    videoRef.current.play()
    setPlaying(true)
  }, [videoRef])

  const pauseVideo = useCallback(() => {
    videoRef.current.pause()
    setPlaying(false)
  }, [videoRef])

  const setRefs = useCallback(
    node => {
      videoRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  const videoSrc = useMemo(() => {
    if (typeof video.src === "string" || video.src instanceof String) {
      return video.src
    }
    // check for small
    if (video.src.src_small && isSmall) {
      return video.src.src_small
    }

    // cheeck for medium
    if (video.src.src_medium && isMedium) {
      return video.src.src_medium
    }

    // check for large
    if (video.src.src_large && isLarge) {
      return video.src.src_large
    }

    return ""
  }, [video, isSmall, isMedium, isLarge])

  useEffect(() => {
    if (!inView) {
      pauseVideo()
    }
  }, [inView, pauseVideo, videoSrc])

  const toggleAudio = () => {
    setMuted(state => !state)
  }

  return (
    <Ratio
      ratio={ratio}
      ref={inViewRef}
      style={{ overflow: "hidden" }}
      aria-hidden={true}
    >
      <ButtonPlayPauseInv onClick={togglePlay} />

      <video
        ref={setRefs}
        width="100%"
        height="100%"
        autoPlay={false}
        controls={false}
        loop={false}
        muted={muted}
        playsInline={true}
        aria-hidden={true}
        onPlay={() => setStarted(true)}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>

      {/* <ReactPlayer
        playsinline
        url={videoSrc}
        muted={muted}
        playing={playing}
        width="100%"
        height="100%"
       
        ref={playerRef}
      /> */}
      {started || <ImgFluid image={poster} />}
      <ControlsBar>
        <ButtonPlayPause playing={playing} onClick={togglePlay} />
        <ButtonMuteUnmute muted={muted} onClick={toggleAudio} />
      </ControlsBar>
    </Ratio>
  )
}
