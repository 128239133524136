import React from "react"
import { motion } from "framer-motion"

import { styled } from "src/stitches"

export const ControlsBar = styled("div", {
  zIndex: 20,
  width: "100%",
  position: "absolute",
  bottom: "0 !important",
  top: "auto !important",
  height: "auto !important",
  opacity: 1,
  padding: "16px",
  display: "flex",
  justifyContent: "flex-start",
  transition: "opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1)",
  color: "$mono50",
  bp3: {
    padding: "24px",
  },

  "& > button": {
    width: "54px",
    height: "54px",
    marginRight: "12px",
    bp3: {
      width: "58px",
      height: "58px",
    },
    bp4: {
      width: "64px",
      height: "64px",
    },
  },
})

export const ButtonPlayPauseInv = styled("button", {
  opacity: 0,
  zIndex: 15,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
})

export const Button = styled("button", {
  "&:hover": {
    color: "$accent",
  },
})

export const ButtonPlayPause = ({ playing = false, ...rest }) => {
  return (
    <Button {...rest}>
      <motion.svg
        viewBox="0 0 64 64"
        initial="paused"
        animate={playing ? "playing" : "paused"}
        fill="none"
        strokeMiterlimit={10}
        style={{ overflow: "visible" }}
        variants={{
          playing: {},
          paused: {},
        }}
      >
        <motion.circle
          cx="32"
          cy="32"
          r="31"
          stroke="currentColor"
          strokeWidth="2"
          variants={{
            home: {},
            back: {},
          }}
          transition={{
            delay: 0.02,
            duration: 0.6,
            type: "tween",
            ease: [0.16, 1, 0.3, 1],
          }}
        />

        <g transform="translate(18,17)">
          <motion.path
            d="M10 7L10.534 6.15451L9 5.18567V7H10ZM10 23H9V24.8143L10.534 23.8455L10 23ZM22.6667 15L23.2007 15.8455L24.5394 15L23.2007 14.1545L22.6667 15ZM11 15V7H9V15H11ZM9 15V23H11V15H9ZM10.534 23.8455L23.2007 15.8455L22.1327 14.1545L9.46601 22.1545L10.534 23.8455ZM9.46601 7.84549L22.1327 15.8455L23.2007 14.1545L10.534 6.15451L9.46601 7.84549Z"
            fill="currentColor"
            transition={{
              duration: 0.5,
              type: "tween",
              ease: [0.16, 1, 0.3, 1],
            }}
            variants={{
              playing: {
                opacity: 0,
              },
              paused: {
                opacity: 1,
              },
            }}
          />
        </g>
        <g transform="translate(17,17)">
          <motion.path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.75 7.5V23.5H12.25V7.5H9.75ZM17.75 7.5V23.5H20.25V7.5H17.75Z"
            fill="currentColor"
            transition={{
              duration: 0.5,
              type: "tween",
              ease: [0.16, 1, 0.3, 1],
            }}
            variants={{
              playing: {
                opacity: 1,
              },
              paused: {
                opacity: 0,
              },
            }}
          />
        </g>
      </motion.svg>
    </Button>
  )
}

export const ButtonMuteUnmute = ({ muted = false, ...rest }) => {
  return (
    <Button {...rest}>
      <motion.svg
        viewBox="0 0 64 64"
        initial="unmuted"
        animate={muted ? "muted" : "unmuted"}
        fill="none"
        strokeMiterlimit={10}
        style={{ overflow: "visible" }}
        variants={{
          muted: {},
          unmuted: {},
        }}
      >
        <motion.circle
          cx="32"
          cy="32"
          r="31"
          stroke="currentColor"
          strokeWidth="2"
          variants={{
            home: {},
            back: {},
          }}
          transition={{
            delay: 0.02,
            duration: 0.6,
            type: "tween",
            ease: [0.16, 1, 0.3, 1],
          }}
        />

        <g transform="translate(19,20)">
          <motion.path
            d="M6 5.86523C3.6211 7.00239 2 9.26262 2 11.8652C2 14.4679 3.6211 16.7281 6 17.8652V15.5544C4.74926 14.6457 4 13.2771 4 11.8652C4 10.4534 4.74926 9.08476 6 8.17603V5.86523Z"
            fill="currentColor"
            transition={{
              duration: 0.5,
              type: "tween",
              ease: [0.16, 1, 0.3, 1],
            }}
            variants={{
              muted: {
                opacity: 0,
              },
              unmuted: {
                opacity: 1,
              },
            }}
          />
        </g>
        <motion.g
          transform="translate(17,17)"
          transition={{
            duration: 0.5,
            type: "tween",
            ease: [0.16, 1, 0.3, 1],
          }}
          variants={{
            muted: {
              opacity: 1,
            },
            unmuted: {
              opacity: 0,
            },
          }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M7.0594 14.9998L9.66262 12.3966L8.60196 11.3359L5.99874 13.9392L3.39709 11.3375L2.33643 12.3982L4.93808 14.9998L2.33643 17.6015L3.39709 18.6621L5.99874 16.0605L8.60196 18.6637L9.66262 17.603L7.0594 14.9998Z"
          />
        </motion.g>
        <g transform="translate(17,17)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M12 4.77343L13.6644 6.25286L19.0052 11.0003H23H24V12.0003V18.0003V19.0003H23H19.0052L13.6644 23.7477L12 25.2271V23.0003V15.0003V7.00027V4.77343ZM14 9.22712V15.0003V20.7734L17.9606 17.2529L18.2448 17.0003H18.625H22V13.0003H18.625H18.2448L17.9606 12.7477L14 9.22712Z"
          />
        </g>
      </motion.svg>
    </Button>
  )
}
